html, body {}

html {
  height: 100%;
}

body {
  min-height: 100%;
  max-width: 100%;
  margin: 0;
  font-family: 'Lato', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 87px;
}

body a {
  text-decoration: none;
  font-weight: bold;
}

#tinymce .mce-content-body p {
  font-family: 'Lato', 'Helvetica Neue', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

select {
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  font-weight: bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*For IE*/

select::-ms-expand {
  display: none;
}

body>#root {
  height: 100vh;
}

th {
  user-select: none;
}

.facebook-bg {
  background-color: #4267B2;
  color: white;
}

.content-container {
  height: 100%;
  max-width: 100%;
}