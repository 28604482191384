.righteous-fontfam {
  font-family: 'Righteous', Helvetica, Arial, cursive;
}

.righteous-container {
  position: absolute;
  left: 20px;
  top: 20px;
}

@media (min-width: 1201px) {
  .righteous-heading {
    font-size: 5em;
  }
  .righteous-year {
    font-size: 7em;
  }
  .righteous-hastag {
    font-size: 3.5em;
  }
  .righteous-container {
    left: 7%
  }
}

@media (max-width: 1200px) {
  .righteous-heading {
    font-size: 4em;
  }
  .righteous-year {
    font-size: 5em;
  }
  .righteous-hastag {
    font-size: 2.5em;
  }
}

@media (max-width: 970px) {
  .righteous-heading {
    font-size: 3em;
  }
  .righteous-year {
    font-size: 4em;
  }
  .righteous-hastag {
    font-size: 0.8em;
  }
}

@media (max-width: 786px) {
  .righteous-heading {
    font-size: 2em;
  }
  .righteous-year {
    font-size: 3em;
  }
  .righteous-hastag {
    font-size: 0.7em;
  }
  .righteous-container {
    top: 10px;
  }
  .sj-button {
    padding: 10px;
    font-size: 1.1em;
  }
}

.sj-button {
  font-family: 'Lato', Helvetica, Arial, cursive;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  padding: 20px;
  background-color: transparent;
  width: 100%;
}

.sj-button-wrapper.primary, .sj-button.primary {
  color: #424242;
  background-color: #99CC00;
}

.sj-button-wrapper.blue, .sj-button.blue {
  color: white;
  background-color: #0090ff;
}

.sj-button-wrapper.red, .sj-button.red {
  color: white;
  background-color: #ff0033;
}

.sj-button-wrapper.purple, .sj-button.purple {
  color: white;
  background-color: #9C27B0;
}

.sj-button-wrapper.facebook .sj-button.facebook {
  background-color: #4267B2;
  color: white;
}

.sj-button-wrapper.black .sj-button.black {
  background-color: black;
  color: white;
}

.sj-button-wrapper.white .sj-button.white {
  background-color: white;
  color: black;
}

.sj-button-wrapper:hover, .sj-button:hover {
  cursor: pointer;
}

.sj-button-wrapper {
  display: inline-block;
}

.sj-button-wrapper.disabled {
  opacity: 0.3;
}

.sj-button:disabled {
  opacity: 0.3;
}

.sj-app-store-icon.ios {
  display: inline-block;
  overflow: hidden;
  width: 135px;
  height: 40px;
  background-size: contain;
}

.sj-app-store-icon.android {
  display: inline-block;
  overflow: hidden;
  width: 150px;
  height: 49px;
  background-size: cover;
}

.leaflet-container {
  min-height: 400px;
  height: 100%;
  width: 100%;
  background-color: 'red'
}

#map-container {
  height: 400px;
}

#map-areas-container {
  min-width: 300px;
  width: 100%;
  height: 100%;
}

.sj-page-body {
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
}

.sj-page-body>span {
  color: 'red';
}

.sj-page-body>strong {
  font-weight: bold;
}

.table-condensed {}

.table-condensed td, .table-condensed th {
  padding: 10px !important;
}

.table-condensed td>button {
  padding: 5px !important;
}

.modal-html-content {
  max-width: 100% !important;
}

.modal-html-content img {
  max-width: 100% !important;
}

.sj-combobox__button {
  border: 1px solid rgba(22, 8, 43, 0.1);
  outline: none;
  background-color: white;
  box-sizing: border-box;
  padding: 12px;
  flex: 0;
  font-size: 14px;
  border-radius: 3px;
  width: 100%;
  height: 65px;
}

.sj-combobox__container {
  border-radius: 3px;
  max-width: 200px;
  width: 300px;
  min-width: 300px;
  left: -15px;
  top: 12px;
  position: absolute;
  box-shadow: 0 1px 3px 0 rgba(22, 8, 43, 0.1);
  border: 1px solid rgba(22, 8, 43, 0.1);
  background-color: white;
  text-align: left;
  max-height: 254px;
  overflow-y: auto;
}

.sj-combobox__item {
  border-bottom: 1px solid rgba(22, 8, 43, 0.1);
  padding: 10px;
  display: flex;
  align-items: center;
}

.sj-combobox__group--header {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  display: flex;
  font-weight: bold;
}

.sj-combobox__item:hover {
  background-color: rgba(22, 8, 43, 0.1);
}